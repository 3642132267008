:root {
  --shadow-color: 0deg 0% 75%;
  --shadow-elevation-low:
    0.2px 0.5px 0.6px hsl(var(--shadow-color) / 0.18),
    0.4px 0.8px 1.1px -1px hsl(var(--shadow-color) / 0.21),
    0.8px 1.6px 2.1px -2px hsl(var(--shadow-color) / 0.23)
  ;
  --shadow-elevation-medium:
    0.2px 0.5px 0.6px hsl(var(--shadow-color) / 0.18),
    0.8px 1.6px 2.1px -0.7px hsl(var(--shadow-color) / 0.21),
    1.8px 3.6px 4.8px -1.3px hsl(var(--shadow-color) / 0.23),
    4.1px 8.2px 11px -2px hsl(var(--shadow-color) / 0.25)
  ;  
  --shadow-elevation-high:
    0.2px 0.5px 0.6px hsl(var(--shadow-color) / 0.2),
    1.3px 2.6px 3.5px -0.3px hsl(var(--shadow-color) / 0.21),
    2.4px 4.9px 6.5px -0.7px hsl(var(--shadow-color) / 0.22),
    4px 8.1px 10.8px -1px hsl(var(--shadow-color) / 0.24),
    6.6px 13.2px 17.7px -1.3px hsl(var(--shadow-color) / 0.25),
    10.5px 21px 28.2px -1.7px hsl(var(--shadow-color) / 0.27),
    16.2px 32.5px 43.6px -2px hsl(var(--shadow-color) / 0.28)
  ;
  --shadow-inset-low:
    inset 0.2px 0.5px 0.6px hsl(var(--shadow-color) / 0.18),
    inset 0.4px 0.8px 1.1px -1px hsl(var(--shadow-color) / 0.21),
    inset 0.8px 1.6px 2.1px -2px hsl(var(--shadow-color) / 0.23)
  ;
  --shadow-inset-medium:
    inset 0.2px 0.5px 0.6px hsl(var(--shadow-color) / 0.18),
    inset 0.8px 1.6px 2.1px -0.7px hsl(var(--shadow-color) / 0.21),
    inset 1.8px 3.6px 4.8px -1.3px hsl(var(--shadow-color) / 0.23),
    inset 4.1px 8.2px 11px -2px hsl(var(--shadow-color) / 0.25)
  ;  
  --shadow-inset-high:
    inset 0.2px 0.5px 0.6px hsl(var(--shadow-color) / 0.2),
    inset 1.3px 2.6px 3.5px -0.3px hsl(var(--shadow-color) / 0.21),
    inset 2.4px 4.9px 6.5px -0.7px hsl(var(--shadow-color) / 0.22),
    inset 4px 8.1px 10.8px -1px hsl(var(--shadow-color) / 0.24),
    inset 6.6px 13.2px 17.7px -1.3px hsl(var(--shadow-color) / 0.25),
    inset 10.5px 21px 28.2px -1.7px hsl(var(--shadow-color) / 0.27),
    inset 16.2px 32.5px 43.6px -2px hsl(var(--shadow-color) / 0.28)
  ;
}