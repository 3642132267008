@import url("./reset.css");
@import url("./open-color.css");
@import url("./shadow-palette.css");
@import url("./font/votevote-symbol.css");

:root {
  --font-sans: "Josefin Sans", sans-serif;

  --color-white: #fffafa;
  --color-white-rgb: 255, 250, 250;
  --color-lesswhite: #f9f4f4;
  --color-lesswhite-rgb: 249, 244, 244;
  --color-backwhite: #f1eeee;
  --color-backwhite-rgb: 241, 238, 238;
  --color-black: #1f373f;
  --color-black-rgb: 31, 55, 63;
  
  background-color: var(--color-white);
  accent-color: var(--oc-pink-3);
}

:root * {
  color: var(--color-black);
}

html,
body {
  padding: 0;
  margin: 0;
  overflow-y: overlay;

  font-family: var(--font-sans);
}

* {
  box-sizing: border-box;
}

hr {
  width: 100%;
  border: none;
  border-top: 1px solid hsl(var(--shadow-color));
}

/* Interaction */
a {
  color: inherit;
  text-decoration: none;
}

summary, button, [role="button"],
input[type=radio]:not(:disabled), input[type=checkbox]:not(:disabled) {
  cursor: pointer;
}  

button, select, input[type="number"] {
  max-width: 100%;
  padding: 0.15rem 0.2rem;
  line-height: 1rem;
  border: none;
  transition: box-shadow 0.1s;
  border-radius: 0.15rem;
  background: none;
}

.symbol {
  font-family: "VoteVote Symbol";
}

button {
  border: 1px solid hsl(var(--shadow-color));
  /* backdrop-filter: invert(0.05); */
  background-color: var(--color-backwhite);
}

button:hover {
  box-shadow: var(--shadow-elevation-medium);
}

select, input[type="number"] {
  width: 3rem;
  border: 1px dashed hsl(var(--shadow-color));
}

select:hover, input:hover {
  box-shadow: var(--shadow-inset-medium);
}
